import Swiper from "swiper/bundle";
import "swiper/css";

document.addEventListener('DOMContentLoaded', function () {
  new Swiper('.testimonials-slider', {
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1.2,
    freeMode: false,
    spaceBetween: 8,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 8,
    speed: 1000,
    touchStartPreventDefault: false,

    breakpoints: {
      // when window width is >= 320px
      1025: {
        loop: false,
        freeMode: true,
        slidesPerView: 1.6,
      },
    },

  });
});

document.getElementById('testimonials').addEventListener('mousedown', function() {
  document.getElementById('custom-cursor-slider').style.transform = 'scale(0.9)';
});

document.addEventListener('mouseup', function() {
  document.getElementById('custom-cursor-slider').style.transform = 'scale(1)';
});

